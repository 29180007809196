var create_accordion_id = function(){
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(a) {
      let r = (new Date().getTime() + Math.random() * 16)%16 | 0, v = a == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
   });
}

var get_ext = function(filename){
	var pos = filename.lastIndexOf('.')
	if (pos === -1) return ''
	return filename.slice(pos + 1)
}

var check_ext = function(filename, allow_exts){
	var ext = getExt(filename).toLowerCase()
	if (allow_exts.indexOf(ext) === -1) return false
	return true
}

var tweet_length = function(text){
  var result = 0;
  var regexp = new RegExp("(^https?| https?|\r\nhttps?|\nhttps?|\rhttps?):\/\/[-_.!~*\'()a-zA-Z0-9;\/?:\@&=+\$,%#]+", 'g')

  url_count = ( text.match(regexp) || [] ).length
  text = text.replace(regexp, '')

  for(var i=0;i<text.length;i++){
    var chr = text.charCodeAt(i);
    if((chr >= 0x00 && chr < 0x81) ||
       (chr === 0xf8f0) ||
       (chr >= 0xff61 && chr < 0xffa0) ||
       (chr >= 0xf8f1 && chr < 0xf8f4)){
      //半角文字の場合は1を加算
      result += 1;
    }else{
      //それ以外の文字の場合は2を加算
      result += 2;
    }
  }

  result += url_count * 24

  return result;
}

var event_handlers = function(){
  $(document)
    .on("click", ".accordion", function(){
      var target = $(this).data('target')
      var toggle = $(this).data('toggle')
      var accordion_status = $(this).data('accordion_status')
      if (accordion_status == 'open') {
        $(toggle + '.close_icon').removeClass('d-none').addClass('show')
        $(toggle + '.open_icon').removeClass('show').addClass('d-none')
        $(target).removeClass('show').addClass('d-none')
        $(this).data('accordion_status', 'close')
      }else{
        $(toggle + '.open_icon').removeClass('d-none').addClass('show')
        $(toggle + '.close_icon').removeClass('show').addClass('d-none')
        $(target).removeClass('d-none').addClass('show')
        $(this).data('accordion_status', 'open')
      }
    })
    .on('cocoon:after-insert', function(e, insertedItem) {
      // アコーディオン用IDの編集
      accordion_id = create_accordion_id()
      new_target = accordion_id
      new_toggle = '.toggle_' + accordion_id
      if(insertedItem.find('.accordion').length > 0){
        default_target = insertedItem.find('.accordion').data('target')
        default_toggle = insertedItem.find('.accordion').data('toggle')
        insertedItem.find('.accordion').data('target', new_target)
        insertedItem.find('.accordion').data('toggle', new_toggle)
        insertedItem.find(default_target).removeClass(default_target.slice(1)).addClass(new_target.slice(1))
        insertedItem.find(default_toggle).removeClass(default_toggle.slice(1)).addClass(new_toggle.slice(1))
      }
    })
}()

$(document).on('turbolinks:load', function(){
  $(window).trigger('resize');
})

window.globalFunction = {};
window.globalFunction.tweet_length = tweet_length;
